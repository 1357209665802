"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../de/_lib/formatDistance/index.js"));
var _index2 = _interopRequireDefault(require("../de/_lib/formatLong/index.js"));
var _index3 = _interopRequireDefault(require("../de/_lib/formatRelative/index.js"));
var _index4 = _interopRequireDefault(require("../de/_lib/match/index.js"));
var _index5 = _interopRequireDefault(require("./_lib/localize/index.js"));
// difference to 'de' locale

/**
 * @type {Locale}
 * @category Locales
 * @summary German locale (Austria).
 * @language German
 * @iso-639-2 deu
 * @author Christoph Tobias Stenglein [@cstenglein]{@link https://github.com/cstenglein}
 */
var locale = {
  code: 'de-AT',
  formatDistance: _index.default,
  formatLong: _index2.default,
  formatRelative: _index3.default,
  localize: _index5.default,
  match: _index4.default,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4
  }
};
var _default = locale;
exports.default = _default;
module.exports = exports.default;