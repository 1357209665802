"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setISODay;
var _index = _interopRequireDefault(require("../_lib/toInteger/index.js"));
var _index2 = _interopRequireDefault(require("../toDate/index.js"));
var _index3 = _interopRequireDefault(require("../addDays/index.js"));
var _index4 = _interopRequireDefault(require("../getISODay/index.js"));
var _index5 = _interopRequireDefault(require("../_lib/requiredArgs/index.js"));
/**
 * @name setISODay
 * @category Weekday Helpers
 * @summary Set the day of the ISO week to the given date.
 *
 * @description
 * Set the day of the ISO week to the given date.
 * ISO week starts with Monday.
 * 7 is the index of Sunday, 1 is the index of Monday etc.
 *
 * @param {Date|Number} date - the date to be changed
 * @param {Number} day - the day of the ISO week of the new date
 * @returns {Date} the new date with the day of the ISO week set
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Set Sunday to 1 September 2014:
 * const result = setISODay(new Date(2014, 8, 1), 7)
 * //=> Sun Sep 07 2014 00:00:00
 */
function setISODay(dirtyDate, dirtyDay) {
  (0, _index5.default)(2, arguments);
  var date = (0, _index2.default)(dirtyDate);
  var day = (0, _index.default)(dirtyDay);
  var currentDay = (0, _index4.default)(date);
  var diff = day - currentDay;
  return (0, _index3.default)(date, diff);
}
module.exports = exports.default;