"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = subBusinessDays;
var _index = _interopRequireDefault(require("../addBusinessDays/index.js"));
var _index2 = _interopRequireDefault(require("../_lib/requiredArgs/index.js"));
var _index3 = _interopRequireDefault(require("../_lib/toInteger/index.js"));
/**
 * @name subBusinessDays
 * @category Day Helpers
 * @summary Substract the specified number of business days (mon - fri) to the given date.
 *
 * @description
 * Substract the specified number of business days (mon - fri) to the given date, ignoring weekends.
 *
 * @param {Date|Number} date - the date to be changed
 * @param {Number} amount - the amount of business days to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 * @returns {Date} the new date with the business days subtracted
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Substract 10 business days from 1 September 2014:
 * const result = subBusinessDays(new Date(2014, 8, 1), 10)
 * //=> Mon Aug 18 2014 00:00:00 (skipped weekend days)
 */
function subBusinessDays(dirtyDate, dirtyAmount) {
  (0, _index2.default)(2, arguments);
  var amount = (0, _index3.default)(dirtyAmount);
  return (0, _index.default)(dirtyDate, -amount);
}
module.exports = exports.default;