"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("./_lib/formatDistance/index.js"));
var _index2 = _interopRequireDefault(require("./_lib/formatLong/index.js"));
var _index3 = _interopRequireDefault(require("./_lib/formatRelative/index.js"));
var _index4 = _interopRequireDefault(require("./_lib/localize/index.js"));
var _index5 = _interopRequireDefault(require("./_lib/match/index.js"));
/**
 * @type {Locale}
 * @category Locales
 * @summary Tamil locale (India).
 * @language Tamil
 * @iso-639-2 tam
 * @author Sibiraj [@sibiraj-s]{@link https://github.com/sibiraj-s}
 */
var locale = {
  code: 'ta',
  formatDistance: _index.default,
  formatLong: _index2.default,
  formatRelative: _index3.default,
  localize: _index4.default,
  match: _index5.default,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4
  }
};
var _default = locale;
exports.default = _default;
module.exports = exports.default;